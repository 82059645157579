import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import innovationImage from "../images/howcan.jpg"
import stakeholderImage from "../images/stakeholder.jpg"
import pbarnesImage from "../images/pbarnes.jpg"
import fishImage from "../images/fish.jpg"

const resources = () => (
  <Layout>
    <SEO title="Resources" />
    <div id="whyInspire" class="px-4 mt-2 mb-5 text-center">
      <h1 class="display-5 fw-bold">Why Inspire?</h1>
      <div class="col-lg-8 mx-auto">
        <p class="lead mb-4 text-start">
          Inspire Global Mobility Consulting Limited (Inspire) was created in
          February 2021 as an independent consultancy. We offer unique industry
          insights derived from over 35 years of experience, encompassing
          mobility program development, over 100 tenders, project management,
          and extensive efforts to innovate and enhance sustainability within
          the Global Mobility sector.
        </p>

        <p class="lead mb-4 text-start">
          Our primary focus is to help Global Mobility and Procurement
          stakeholders maximise value from their Service Partners. This can be
          achieved through a review or audit of existing practices, a tender
          process (PQQ, RFI, RFP, RFQ), or broader policy and program review.
        </p>
        <p class="lead mb-4 text-start">
          The consultancy also covers the important areas of Sustainability,
          Compliance, Audit, Insourcing, Outsourcing and Technology to ensure
          educated decisions are taken and the desired results achieved.
        </p>
        <p class="lead text-start">
          Paul Barnes, the head of Inspire consultancy, brings over 35 years of
          expertise in Global Mobility. He has a proven track record in
          consultancy, project management, senior management, and business
          development.
        </p>
        <p class="lead text-start">
          Paul’s extensive experience stems from his work with multinational
          companies across various industries, nationalities, and cultures. This
          has equipped him with valuable knowledge, cultural awareness, and life
          skills, which he leverages to consult with stakeholders, understand
          their objectives, and deliver long-term strategic value.
        </p>
        <p class="lead text-start">
          Paul is passionate about discovering and applying innovative mobility
          solutions, creating meaningful value through service provider
          development, policy reforms, program enhancements and technology. He
          has a strong interest in sustainability, actively engaging with and
          championing the efforts of stakeholders to minimise the carbon
          footprint and enhance environmental and social impacts.
        </p>
        <p class="lead text-start">
          For tender (RFP) consultancy engagements, Inspire exclusively
          collaborates with a corporation’s in- house Global Mobility and
          Procurement stakeholders, rather than Relocation Service Partners.
          This approach guarantees that Inspire provides transparent advice and
          guidance to clients. Our independence and unique market position
          assure clients that they receive advice and knowledge aimed solely at
          helping them overcome their unique challenges and achieve maximum
          value.
        </p>
        <p class="lead">
          <strong>
            Let Inspire spark new ideas and bring your vision to life helping
            you achieve the maximum value from your project
          </strong>
        </p>
        <p class="lead text-start">
          Please feel free to reach out for advice or a free 30-minute discovery
          call to discuss how we can help ensure the success of your project.
        </p>
      </div>
    </div>

    <div class="rotate" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <div
      id="expertiseAndKnowledge"
      class="px-4 text-center bg-coolblue text-light"
    >
      <h1 class="display-5 fw-bold">Expertise And Knowledge?</h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4 text-start">
          Staying updated on the challenges and potential solutions for Global
          Mobility and Service Partners is crucial for enabling informed
          discussions, sharing of innovative ideas and helping clients make
          well- informed decisions and justify their actions.
        </p>

        <div class="row flex-lg align-items-center g-5">
          <div class="col-sm-6">
            <img
              src={pbarnesImage}
              class="rounded-pill d-block mx-lg-auto img-fluid w-75"
              alt="..."
            />
          </div>

          <div class="col-sm-6">
            <p class="lead mb-4 text-start">
              Inspire promotes the sharing of knowledge to positively educate
              and influence the industry to improve the tender process,
              transparency and increase sustainability. Paul founded the{" "}
              <a
                class="link-text text-light underline"
                target="_blank"
                href="https://www.linkedin.com/groups/9043947/"
              >
                <u>Sustainability &amp; ESG In Global Mobility group</u>
              </a>{" "}
              to drive the sustainability agenda and enhance collaboration among
              all stakeholders.
            </p>
            <p class="lead mb-4 text-start">
              Paul actively connects with key thought leaders and associations
              in Global Mobility and related industries. He regularly engages in
              knowledge sharing and idea exchange with his peers.
            </p>
          </div>
        </div>
        <p class="lead mt-4 mb-4 text-start">
          By receiving and reviewing industry updates, he stays informed about
          challenges, trends, new entrants, and potential initiatives in Global
          Mobility. His involvement with recognised industry associations and
          groups includes the following:
        </p>

        <ul class="lead text-start">
          <li class="mb-2">Participant in focus groups</li>
          <li class="mb-2">Delivering educational modules</li>
          <li class="mb-2">Conference speaking</li>
          <li class="mb-2">Writing articles</li>
          <li>Webinars</li>
        </ul>
        <p class="lead mt-4 mb-4 text-start">
          Across the following Associations:
        </p>

        <ul class="lead text-start">
          <li class="mb-2">
            EuRA - Professional industry body for relocation Service Partners
            and affiliated services
          </li>
          <li class="mb-2">FEM - Forum For Expatriate Management</li>
          <li class="mb-2">
            FIDI - Global alliance of quality-certified international moving and
            relocation companies
          </li>
          <li class="mb-2">OMNI - Overseas Moving Network International</li>
          <li class="mb-2">
            WERC - Employee Relocation Council (Sustainability Advisory Council
            Member)
          </li>
        </ul>
        <p class="lead mb-0 mt-5">
          <strong>
            Leverage our knowledge and expertise to achieve your objectives!{" "}
          </strong>
        </p>
      </div>
    </div>

    <div class="rotate-180" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <div id="howInspireCanHelpYou" class="container-flex">
      <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5">
          <div class="col-lg-6">
            <img
              src={innovationImage}
              class="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
          <div class="col-lg-6 ">
            <h1 class="display-5 fw-bold lh-1 mb-3">
              Tender Strategy and Management{" "}
            </h1>
            <p class="lead">
              With over 35-years of industry experience, Inspire specialise in
              assisting in-house Global Mobility and Procurement professionals
              develop a successful strategy and management of their tender
              process (PQQ, RFI, RFP, RFQ) to source Global Mobility services in
              the following areas:
            </p>
            <ul type="1" class="lead text-start">
              <li class="mb-2">Assignment Management</li>
              <li class="mb-2">Destination Services</li>
              <li class="mb-2">Household Goods Moving</li>
              <li class="mb-2">Immigration</li>
              <li class="mb-2">Technology</li>
              <li>Tax</li>
            </ul>
            <p class="lead"></p>
          </div>
          <div class="px-4 m-0">
            <div class="col-lg-12 mx-auto">
              <p class="lead mb-4 text-start">
                Navigating the tender process can be complex and very
                time-consuming, especially when it comes to tender strategy,
                planning, delivery and achieving your goals. That’s where
                Inspire comes in.
              </p>
              <p class="lead text-center">
                <strong>What we do?</strong>
              </p>
              <p class="lead mb-4 text-start">
                We provide expert consultation to streamline and add value to
                your review or audit of existing practices, a tender process
                (PQQ, RFI, RFP, RFQ), or broader policy and program review. Our
                focus is on sharing our knowledge and unique insights that save
                time and enhance your tender process. With our assistance, you
                can expect a smooth, efficient process and successful result.
              </p>
              <p class="lead mb-4 text-start">
                Our guiding principles are to make the process as simple,
                efficient, and engaging for all stakeholders to increase its
                impact and ultimate success. Our adaptable approach allows you
                to leverage our knowledge and experience at any stage of your
                activities.
              </p>
            </div>
            <div class="row bg-coolorange text-light rounded-3 coolshadow pt-4 pb-4 mb-4">
              <div class="col-lg-12">
                <p class="lead text-center">
                  <strong>The Value We Deliver</strong>
                </p>
                <ol>
                  <li class="lead mb-2">
                    <strong>Strategy:</strong> Each project is unique and we
                    assist you to develop the strategy that best aligns with
                    your specific needs and goals. Including stakeholder
                    alignment workshops to identify and unify stakeholder goals.
                  </li>
                  <li class="lead mb-2">
                    <strong>Planning:</strong> Effective preparation and
                    planning are crucial. This includes defining your project
                    requirements, establishing achievable goals, and a clear
                    timeline of actions.
                  </li>
                  <li class="lead mb-2">
                    <strong>Effciency:</strong> By focusing on important
                    deliverables, we help you reclaim valuable time, allowing
                    you to concentrate on core business operations. We help
                    create project documents focused on your unique requirements
                    for example in a tender to maximise the quality of your
                    bidders’ responses and pricing.
                  </li>
                  <li class="lead mb-2">
                    <strong>Expertise:</strong> With our extensive experience,
                    we bring a wealth of knowledge, fresh perspectives, and
                    unique insights to the table that add value whilst saving
                    you time and money.
                  </li>
                  <li class="lead mb-2">
                    <strong>Success:</strong> Our strategic planning and
                    delivery of the process are designed to deliver positive
                    outcomes, ensuring the success of your project and mobility
                    program.
                  </li>
                </ol>
                <p class="lead mb-4">
                  We help ensure our clients achieve high-quality, timely
                  results. We typically deliver the greatest value for clients
                  when involved at the start of their journey to help define and
                  achieve their goals.
                </p>
                <p class="lead mb-4">
                  The following are example of how Inspire can assist and add
                  value to your tender process:
                </p>
                <ul>
                  <li class="lead">
                    Define success criteria and align to the evaluation criteria
                  </li>
                  <li class="lead">
                    Preparing the tender request, including scope, pricing,
                    technical questions, evaluation
                  </li>
                  <li class="lead">Identify additional value opportunities</li>
                  <li class="lead">
                    Identify and remove loopholes bidders may take advantage of
                  </li>
                  <li class="lead">Answer bidders technical questions</li>
                  <li class="lead">
                    Evaluation process, comparing bids and raising
                    clarifications
                  </li>
                  <li class="lead">
                    Formatting evaluation, delivery of best and final
                    presentations
                  </li>
                  <li class="lead">
                    Contracting questions, including SLA, KPI’s to ensure
                    effective performance management against the contract
                  </li>
                  <li class="lead">Implementation phase</li>
                  <li class="lead">Non-compliance, billing accuracy audit</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12 mx-auto">
              <p class="lead text-center">
                <strong>Stakeholder Engagement</strong>
              </p>
              <p class="lead mb-4">
                An important but often missed powerful dynamic where Inspire
                adds value for clients is the positive engagement throughout the
                project with internal and external stakeholders.
              </p>
              <p class="lead mb-4">
                Identifying value for each stakeholder group is essential,
                whilst positioning that value in the messaging for example of a
                service partner or policy change. Effective messaging
                significantly enhances stakeholder engagement and facilitates
                their acceptance of the change.
              </p>
              <p class="lead mb-4">
                Examples of the benefits of positive engagement in a tender
                process include:
              </p>
              <ul>
                <li class="lead">Shorter more informative answers </li>
                <li class="lead">
                  Reduce the time to evaluate and score each submission
                </li>
                <li class="lead">
                  Effective contract and pricing negotiations
                </li>
                <li class="lead">On time implementation and go live </li>
              </ul>

              <p class="lead text-center">
                <strong>Quality Cost Savings</strong>
              </p>
              <p class="lead mb-4">
                By focusing on our clients’ goals, we often find opportunities
                to improve quality and deliver greater value. For instance,
                achieving significant efficiencies and cost savings without
                compromising service quality requires a high level of
                transparency and stakeholder engagement. Inspire facilitates
                this essential stakeholder engagement throughout the project.
              </p>
              <p class="lead mb-4">
                Relating to Mobility Service Partners inspire has an extensive
                knowledge of operating models commission structures and profit
                areas. Understanding the Service Partners commission structure
                (referral fees) from their supply chain and ultimately where
                they make their profit enables clients to understand areas where
                transparency and savings can be achieved.
              </p>

              <p class="lead text-center">
                <strong>Technology Innovation</strong>
              </p>
              <p class="lead mb-4">
                Inspire is familiar with current and potential future innovative
                ideas in mobility. This includes the main technology platforms
                and tools that are used by in-house Global Mobility
                professionals and the services partners. This insight is
                essential to ensure Clients asks the right questions based upon
                their needs and obtains the right commitments from service
                partners.
              </p>
              <p class="lead mb-4">
                <strong>Technology Review:</strong> Inspire can assist
                with formatting and delivering a comprehensive technology needs
                analysis. This analysis will determine the specific technology
                requirements for each stakeholder group, ensuring that all
                client needs are accurately identified and addressed. In recent
                years, companies have frequently spent considerable time
                evaluating Service Partners’ technology during mobility RFPs,
                often making decisions based on perceived capabilities rather
                than actual performance.
              </p>
              <p class="lead mb-4">
                <strong>Data Requirements &amp; Flow:</strong> Global Mobility
                teams often find themselves overwhelmed with unnecessary data
                and burdened by time-consuming administrative tasks. Inspire can
                assist by reviewing the data flow required by all stakeholders,
                identifying efficiencies for our clients. This review can also
                uncover opportunities to reduce administrative workload and
                highlight areas suitable for outsourcing.
              </p>

              <p class="lead text-center">
                <strong>Reporting &amp; KPIs</strong>
              </p>
              <p class="lead mb-4">
                When it comes to service delivery, it’s crucial to review
                historical reporting and KPIs while understanding each
                stakeholder’s needs to ensure efficient reporting and KPIs. Our
                approach involves consolidating KPIs where possible and
                formalising them into data and dashboards. Typically, we help
                develop a consolidated dashboard that offers a high-level
                overview of the entire program, accompanied by detailed reports
                and dashboards to meet all stakeholder needs. We ensure
                simplicity with clear deliverables, such as Spend, Case numbers
                per policy and region, Employee satisfaction, Compliance,
                Quality, and Data integrity.
              </p>
              <p class="lead mb-4">
                Whether you’re in the early planning stages or need support at
                any point during your project we are here to assist. Please feel
                free to reach out for advice or a free 30-minute discovery call
                to discuss how we can help ensure the success of your project.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rotate" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <div id="sustainability" class="container-flex bg-coolblue">
      <div class="container col-xxl-8 px-4">
        <div class="row flex-lg-row align-items-center g-5 bg-coolblue">
          <div class="col-lg-6">
            <img
              src={fishImage}
              class="d-block mx-lg-auto img-fluid rounded"
              alt="Bootstrap Themes"
              width="612"
              height="414"
              loading="lazy"
            />
          </div>
          <div class="col-lg-6 text-light">
            <h1 class="display-5 fw-bold lh-1 mb-3">Sustainability</h1>
            <p class="lead">
              Paul possesses a wide range of knowledge across the scope
              of sustainability. His expertise spans various topics, including
              the Circular Economy, Scope 1, 2, and 3
              Emissions, Materiality, Science- Based Targets (SBTi), the UN
              Global Compact, Sustainable Development Goals (SDGs), Global
              Reporting Initiative (GRI) standards, Carbon Disclosure Project
              (CDP), EcoVadis and the important areas of long-term planning,
              strategy and implementation.
            </p>
            <p class="lead text-start">
              Paul’s thought leadership extends beyond theory. He has developed
              and implemented sustainable practices and has
              been published and quoted by organizations such
              as EuRA, FEM, FIDI, OMNI and Mover magazine. He is also the
              visionary behind the industry’s first sustainability educational
              program for EuRA which launched in January 2022. As a dedicated
              advocate, Paul actively contributes to sustainable practices and
              serves as a member of the WERC Sustainability Advisory Council.
            </p>
          </div>
          <div class="px-4 text-center bg-coolblue text-light m-0">
            <div class="col-lg-12 mx-auto">
              <p class="lead text-start">
                Furthermore, Paul collaborates with global mobility
                professionals and service partners, guiding them toward
                transformative thinking, practices and policies that lead to
                a positive and more sustainable future.
              </p>
              <p class="lead text-start">
                Here are a few examples of projects aimed at reducing clients
                environmental and social impact.
              </p>
              <ul type="1" class="lead text-start">
                <li class="mb-2">
                  <strong>Carbon Footprint Reduction:</strong> Collaborating
                  with clients to develop strategies that minimise their carbon
                  emissions. Examples include employee engagement, implementing
                  energy efficient practices, sustainable transportation and
                  renewable energy sources.
                </li>
                <li class="mb-2">
                  <strong>Waste Reduction:</strong> Assisting clients to create
                  more sustainable supply chains by measurement and reduction.
                  Examples include responsibly sourcing materials, applying
                  circularity principles to reuse and keep materials at their
                  highest value with recycling as a last option to minimise
                  waste across their business and supply chain.
                </li>
                <li class="mb-2">
                  <strong>Community Engagement</strong>: Focusing on the social
                  aspect by helping companies engage with local communities to
                  ensure that mobility projects benefit everyone involved. 
                  Initiatives include helping companies improve infrastructure
                  that supports local economies and providing training programs
                  for local workers.
                </li>
                <li class="mb-2">
                  <strong>Policy Advocacy:</strong> We are an advocate for
                  developing corporate policies that support sustainable
                  practices to reduce emissions and waste whilst improving
                  social well-being.
                </li>
                <li class="mb-2">
                  <strong>Systemic Mindset Change:</strong> Understanding how we
                  think and act, with the use of Nudge Theory and other methods
                  to help people comprehend the consequences of their actions,
                  while making sustainable choices simple and rewarding
                </li>
              </ul>
              <p class="lead mb-4 text-start">
                These initiatives underscore our dedication to clients and our
                commitment to fostering a more sustainable and socially
                responsible approach to global mobility.
              </p>
              <p class="lead mb-4 text-start">
                If you have a specific project or area of interest please
                contact us for advice or a free 30-minute discovery call to
                discuss how we can help ensure the success of your project.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rotate-180" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <div id="howIsYourProgramPerforming" class="container-flex ">
      <div class="container col-xxl-8 px-4 py-5 ">
        <div class="row flex-lg align-items-center g-5">
          <div class="col-lg-6">
            <img
              src={stakeholderImage}
              class="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold lh-1 mb-3">
              How effective are your Service Partners?
            </h1>
            <p class="lead">
              This range of services is designed to help clients resolve issues
              with their service partners or assess current partners to find
              cost and administrative efficiencies, while also discovering new
              value opportunities
            </p>
            <p class="lead">
              Are you getting value for money? When was the last time you
              audited the accuracy of your service partners invoices against
              contracted rates? If you are undecided about going to tender, or
              you are extending an existing contract then these services are
              ideally suited to you.
            </p>
          </div>
          <div class="col-lg-12 mx-auto mt-2">
            <p class="lead">
              Evaluating whether you have a quality service partner and if they
              value your business involves several key steps which can include
              the following:
            </p>
            <ul class="lead text-start">
              <li>
                <strong>Assess Service Quality:</strong> Look at the consistency
                and reliability of the services provided. Are they meeting your
                expectations and industry standards?
              </li>
              <li>
                <strong>Customer Feedback:</strong> Gather feedback from your
                team, relocating employees and wider stakeholders. Are there
                trends such as recurring issues or positive feedback?
              </li>
              <li>
                <strong>Communication and Responsiveness:</strong> Evaluate how
                well your service partner communicates. Are they responsive to
                your needs and concerns? Good partners should be proactive in
                addressing issues and transparent in their communication.
              </li>
              <li>
                <strong>Value for Money:</strong> Consider whether the services
                provided are worth the cost or accurately priced. This doesn’t
                always mean choosing the cheapest option but rather the one that
                offers you the best value in terms of achieving your goals.
              </li>
              <li>
                <strong>Compliance:</strong> We can assist to audit your service
                partners to help ensure the correct invoicing against the
                contract rates and compliance with contract conditions.
              </li>
              <li>
                <strong>Partnership Approach:</strong> Are your partners
                invested in your success and willing to go the extra mile to
                support your goals. A quality service partner should see your
                relationship as a partnership
              </li>
              <li>
                <strong>Performance Metrics:</strong> Do you and your service
                partners use simple but effective metrics to measure
                performance, spot trends and ensure improvement.
              </li>
            </ul>
          </div>
          <div class="px-4 text-center  m-0">
            <div class="col-lg-12 mx-auto">
              <p class="lead mb-4 text-start">
                Inspire provides independent advice and unique market
                intelligence to help clients systematically evaluate the right
                criteria to determine if their service partners are truly
                delivering quality, cost effective service and if they value
                your business.
              </p>
              <p class="lead">
                Please feel free to reach out for advice or a free 30-minute
                discovery call to discuss how we can help ensure the success of
                your project.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rotate" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <div id="needAnalysis" class="px-4 text-center bg-coolblue text-light">
      <h1 class="display-5 fw-bold">Initial engagement</h1>
      <div class="col-lg-8 mx-auto">
        <p class="lead mb-4 text-start">
          Our needs analysis typically involves several key steps to understand
          and address a client’s specific challenges and goals. The following
          are steps that are typically included depending on the complexity of a
          client’s objectives.
        </p>
        <ul class="lead text-start">
          <li>
            <strong>Initial Consultation:</strong> Our first meeting with a
            client to discuss their requirements, project’s scope, objectives,
            and any immediate concerns. This is an opportunity to discover a
            client’s needs and establish how Inspire can help.
          </li>
          <li>
            <strong>Needs Assessment:</strong> We evaluate our client’s needs in
            more detail, whilst considering their short to long-term goals.
          </li>
          <li>
            <strong>Solution Development:</strong> Proposing actionable
            solutions and strategies to address the identified needs. This
            normally includes a comprehensive report that outlines the findings,
            recommendations, and the steps required to achieve success.
          </li>
          <li>
            <strong>Benchmarking:</strong> Comparing the service providers
            performance with industry standards and best practices to identify
            areas for improvement can add great value to the solution
            development in certain circumstances.
          </li>
          <li>
            <strong>Data Collection:</strong> The consultant gathers relevant
            information through various methods such as stakeholder engagement,
            surveys, document reviews, and observations. This data can help in
            understanding the current state and the solution development
          </li>
          <li>
            <strong>Feedback and Adjustment:</strong> Discussion and feedback to
            assess the effectiveness of the proposed solutions and makes
            necessary adjustments to ensure the desired outcomes are achieved.
          </li>
          <li>
            <strong>Strategy &amp; Planning:</strong> Developing a detailed
            strategy and plan for implementing the proposed solutions and
            actions, including timelines, resource allocation, and key
            performance indicators (KPIs) to measure success.
          </li>
          <li>
            <strong>Project Management:</strong> Implementing and proactively
            managing the project through Microsoft Project or Planner management
            tools or a client’s specific software solution. This approach saves
            clients valuable time and ensures a proactive approach to meet
            required deadlines.
          </li>
        </ul>
        <p class="lead text-start">
          This structured approach helps ensure that our recommendations are
          well-informed and tailored to each client’s specific situation.
        </p>
        <p class="lead text-start">
          If you have a specific project or area of interest, please reach out
          to us so we can discuss how we can assist you in achieving your goals.
        </p>
        <p class="lead text-start mb-0">
          Please feel free to reach out for advice or a free 30-minute discovery
          call to discuss how we can help ensure the success of your project.
        </p>
      </div>
    </div>
    <div class="rotate-180" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  </Layout>
)

export default resources
